<template>
  <div class="input-field">
    <div class="input-container" :class="hasError ? 'error' : ''">
      <input type="text" v-model="inputValue" :placeholder="placeholderText" @input="addSpaces" @blur="handleBlur"/>
    </div>
    <transition
      name="fade"
      mode="out-in"
      enter-active-class="animate__animated animate__fadeInDown animate__faster"
      leave-active-class="animate__animated animate__fadeOutUp  animate__faster"
    >
      <div v-if="errorMessage" class="input-error">
        {{ errorMessage }}
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "InputField",
  props: {
    label: {
      type: String,
    },
    errorMessage: {
      type: String,
    },
    hasError: {
      type: Boolean,
    },
    inputType: {
      type: String,
      default: "default"
    },
    placeholderText: {
      type: String,
    }
  },
  data: () => ({
    inputValue: undefined,
  }),
  methods: {
    addSpaces() {
      if (this.inputType == 'BWID') {
        let valueWithoutSpaces = this.inputValue.replace(/\s/g, '');
        let formattedValue = valueWithoutSpaces.replace(/(.{4})/g, '$1 ').trim();
        this.inputValue = formattedValue.slice(0, 19);
      }
    },
    handleBlur() {
      this.$emit('blur');
    }
  },
  watch: {
    inputValue() {
      this.$emit('changed', this.inputValue);
    }

  }
}
</script>

<style lang="scss" scoped>
.input-field {
  .label {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
  }
  .input-container {
    border: 1px solid white;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.1);
    margin-top: 8px;
    input {
      color: white;
      background: none;
      border: none;
      height: 55px;
      caret-color: #FCBB11;
      font-size: 16px;
      line-height: 18px;
      padding-left: 13px;
      width: 100%;
      &:focus {
        outline: none;
      }
    }
    &.error {
      border: 1px solid #FE6161;
      background: rgb(254, 97, 97, 0.1);
      input {
        color: #FE6161;
      }
    }
  }
  .input-error {
    margin-top: 8px;
    margin-left: 13px;
    color: #FE6161;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
  }
}
</style>