<template>
  <div id="joinplatform-modal">
    <div class="modal-content" :class="isError ? 'error' : ''">
      <img v-if="!isError" src="/images/screens/joinplatform/success.png">
      <div class="paragraph">
        {{ isError ? $t('joinPlatformScreen.modal.error.paragraph') : $t("joinPlatformScreen.modal.paragraph")  }}
      </div>
      <base-button class="button" :text="isError ? $t('joinPlatformScreen.modal.error.button') : $t('joinPlatformScreen.modal.button') " @clicked="handleButtonClick()" />
    </div>
  </div>
</template>

<script>
import Button from "@/components/elements/Button";
export default {
  name: "JoinplatformModal",
  components: {
    Button,
  },
  props: {
    isError: {
      type: Boolean,
    },
  },
  methods: {
    handleButtonClick() {
      if (!this.isError) {
        document.body.style.overflow = 'auto';
        location.href = '/';
      } else {
        document.body.style.overflow = 'auto';
        this.$emit("close");
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.joinplatform-modal {
  background: rgb(8, 5, 55, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 15px;
  .modal-content {
    background: white;
    max-width: 540px;
    min-height: 302px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 34px 27px;
    &.error {
      min-height: 240px;
      .paragraph {
        margin-top: 24px;
      }
      .button {
        margin-top: 54px;
      }
    }
    img {
      width: 40px;
      height: 40px;
    }
    .paragraph {
      margin-top: 34px;
      font-size: 16px;
      font-weight: 700;
      line-height: 25px;
      letter-spacing: 0em;
      text-align: center;
    }
    .button {
      margin-top: 34px;
      height: 58px;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0px;
      text-align: center;
    }
  }
}
</style>