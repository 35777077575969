<template>
  <div id="join-platform">
    <div class="content-container">
      <div class="title">
        {{ $t('joinPlatformScreen.title') }}
      </div>
      <div class="subtitle">
        {{ $t('joinPlatformScreen.subtitle') }}
      </div>
      <div class="input-section">
        <div class="label">
          {{ $t("joinPlatformScreen.labels.walletId") }}
        </div>
        <input-field inputType="BWID"
          @changed="setBWId"
          @blur="validateBWId"
          :hasError="BWIdError"
          :placeholderText="$t('joinPlatformScreen.placeholders.walletId')"
        />
        <div class="wallet-id-info">
          {{ $t("joinPlatformScreen.labels.walletIdInfo")}}
        </div>
      </div>
      <div class="input-section">
        <div class="label">
          {{ $t("joinPlatformScreen.labels.name") }}
        </div>
        <input-field
          @changed="setName"
          :placeholderText="$t('joinPlatformScreen.placeholders.name')"
        />
      </div>
      <div class="input-section">
        <div class="label">
          {{ $t("joinPlatformScreen.labels.email") }}
        </div>
        <input-field
          :hasError="emailError"
          @blur="validateEmail"
          @changed="setEmail"
          :placeholderText="$t('joinPlatformScreen.placeholders.email')"
        />
      </div>
      <div class="input-section">
        <div class="label">
          {{ $t("joinPlatformScreen.labels.phone") }}
        </div>
        <input-field
          @changed="setPhone"
          @blur="validatePhone"
          :hasError="phoneError"
          :placeholderText="$t('joinPlatformScreen.placeholders.phone')"
        />
      </div>
      <div class="input-section">
        <div class="label">
          {{ $t("joinPlatformScreen.labels.project") }}
        </div>
        <div class="sub-label">
          {{ $t("joinPlatformScreen.labels.projectSubLabel") }}
        </div>
        <textarea class="text-area"
          maxlength="500"
          v-model="attrs.projectDetails"
          @blur="validateProjectDetails"
          @keyup="projectDetailsError = false"
          :class="projectDetailsError ? 'error' : ''"
          :placeholder="$t('joinPlatformScreen.placeholders.project')"
        ></textarea>
      </div>
      <div class="check-section">
        <checkbox-field
          @changed="checkAll"
          :newValue="attrs.checkAll"
          :label="$t('joinPlatformScreen.labels.checkAll')"
        />
        <div class="subchecks">
          <checkbox-field
            @changed="checkPP"
            :newValue="attrs.checkPP"
            style="margin-top: 14px"
            :size="18"
            :labelPadding="8"
            :labelSize="12"
            :label="$t('joinPlatformScreen.labels.checkPP')"
          />
          <checkbox-field
            @changed="checkPrice"
            :newValue="attrs.checkPrice"
            style="margin-top: 14px"
            :size="18"
            :labelPadding="8"
            :labelSize="12"
            :label="$t('joinPlatformScreen.labels.checkPrice')"
          />
        </div>
      </div>
      <base-button class="button" :class="disableButton ? 'disabled' : ''" type="blockstock-yellow" :text="$t('joinPlatformScreen.buttons.send')" @clicked="handleSubmit()" />
      <div class="paragraphs">
        <div class="first"> {{ $t('joinPlatformScreen.paragraphs.first') }} </div>
        <div class="second"> {{ $t('joinPlatformScreen.paragraphs.second') }} </div>
      </div>
      <base-button class="button" type="blockstock-yellow" :text="$t('joinPlatformScreen.buttons.downloadInfo') " @clicked="handleDownload()"/>
      <base-button class="button" type="blockstock-yellow" :text="$t('joinPlatformScreen.buttons.goToBW')" @clicked="handleGoToBW()"/>
      <div class="stores">
        <store-button :version="'apple'"/>
        <store-button :version="'google'"/>
      </div>
    </div>
    <joinplatform-modal
      v-if="showJoinplatformModal"
      :isError="hasError"
      @close="handleClose"
      class="joinplatform-modal"
    />
  </div>
</template>

<script>
import InputField from "@/components/elements/InputField";
import CheckboxField from "@/components/elements/CheckboxField";
import Button from "@/components/elements/Button";
import JoinplatformModal from "@/components/elements/JoinplatformModal";
import Api from "@/api";
import { mapGetters } from 'vuex';

export default {
  name: "JoinPlatform",
  components: {
    InputField,
    CheckboxField,
    Button,
    JoinplatformModal
  },
  data: () => ({
    attrs: {
      BWId: undefined,
      name: undefined,
      email: undefined,
      phone: undefined,
      projectDetails: undefined,
      checkAll: false,
      checkPP: false,
      checkPrice: false,
    },
    changeSubCheckers: false,
    changeCheckAll: false,
    emailError: false,
    BWIdError: false,
    phoneError: false,
    projectDetailsError: false,
    showJoinplatformModal: false,
    hasError: false,
  }),
  methods: {
    setBWId(payload) {
      this.attrs.BWId = payload;
      this.BWIdError = false;
    },
    setName(payload) {
      this.attrs.name = payload;
    },
    setEmail(payload) {
      this.attrs.email = payload;
      this.emailError = false;
    },
    setPhone(payload) {
      this.attrs.phone = payload;
      this.phoneError = false;
    },
    checkAll(payload) {
      this.attrs.checkAll = payload;
      this.attrs.checkPP = payload;
      this.attrs.checkPrice = payload;
    },
    checkPP(payload) {
      this.attrs.checkPP = payload;
      if (this.attrs.checkPP && this.attrs.checkPrice) {
        this.attrs.checkAll = true;
      } else {
        this.attrs.checkAll = false;
      }
    },
    checkPrice(payload) {
      this.attrs.checkPrice = payload;
      if (this.attrs.checkPP && this.attrs.checkPrice) {
        this.attrs.checkAll = true;
      } else {
        this.attrs.checkAll = false;
      }
    },
    validateEmail() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.emailError = !emailRegex.test(this.attrs.email);
    },
    async validateBWId() {
      let idWithoutSpace = this.attrs.BWId.replace(/\s/g, '');
      const res = await Api.get(`joinplatform/wallet/${idWithoutSpace}`);
      const response = await res.json();
      if (response.status != "OK") {
        this.BWIdError = true;
      }
    },
    validatePhone () {
      const emailRegex = /^\+(\d+)$/;
      this.phoneError = !emailRegex.test(this.attrs.phone);
		},
    validateProjectDetails() {
      if (this.attrs.projectDetails == undefined || this.attrs.projectDetails?.length < 5) {
        this.projectDetailsError = true;
      }
    },
    handleSubmit() {
      if (!this.disableButton) {
        const captchaSiteKey = '6Lcg3A4pAAAAAISp2klJIpH422mq8762zdxYmUV8'
        const grecaptcha = window.grecaptcha
        let idWithoutSpace = this.attrs.BWId.replace(/\s/g, '');
        const payload = {
          recaptcha: undefined,
          registration: {
            walletID: idWithoutSpace,
            email: this.attrs.email,
            phone: this.attrs.phone,
            description: this.attrs.projectDetails,
            accept: {
              dataProcess: this.attrs.checkPP,
              feeDeduct: this.attrs.checkPrice,
            }
          }
        }
        if (grecaptcha) {
          grecaptcha.ready(() => {
            grecaptcha
              .execute(captchaSiteKey, { action: 'submit' })
              .then(async (token) => {
                payload.recaptcha = token;
                const res = await Api.post('joinplatform/candidate', payload);
                const response = await res.json();
                if (response.status == "OK") {
                  document.body.style.overflow = 'hidden';
                  this.showJoinplatformModal = true;
                } else {
                  document.body.style.overflow = 'hidden';
                  this.hasError = true;
                  this.showJoinplatformModal = true;
                }
              })
              .catch(error => {
                this.hasError = true;
                this.showJoinplatformModal = true;
                console.error('reCaptcha error:', error)
              })
          })
        }
      }
    },
    handleClose() {
      this.showJoinplatformModal = false;
      this.hasError = false;
      document.body.style.overflow = 'auto';
    },
    handleGoToBW() {
      location.href = 'https://wallet.blockben.com/'
    },
    handleDownload() {
      if (this.selectedLanguage == 'hu') {
        window.open('https://data.blockben.com/platform/platformtajekoztato.pdf', '_blank');
      } else {
        window.open('https://data.blockben.com/platform/platformhandout.pdf', '_blank')
      }
    }
  },
  computed: {
    ...mapGetters({
      selectedLanguage: 'settings/getSelectedLanguage',
    }),
    disableButton() {
      return (
        this.attrs.BWId == undefined ||
        this.BWIdError ||
        this.attrs.name == undefined ||
        this.attrs.email == undefined ||
        this.emailError ||
        this.attrs.phone == undefined ||
        this.phoneError ||
        this.attrs.projectDetails == undefined ||
        this.projectDetailsError ||
        this.attrs.checkPP == false ||
        this.attrs.checkPrice == false
      )
    }
  },
}
</script>

<style lang="scss" scoped>
#join-platform {
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 100px;
  background: url('/images/screens/joinplatform/joinplatform_bg.png') no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  .content-container {
    padding-top: 54px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .title {
      max-width: 730px;
      font-size: 34px;
      font-weight: 700;
      line-height: 47px;
    }
    .subtitle {
      margin-top: 29px;
      max-width: 540px;
      font-size: 16px;
      font-weight: 400;
      line-height: 25px;
    }
    .input-section {
      margin-top: 27px;
      max-width: 350px;
      width: 100%;
      .label {
        font-size: 14px;
        font-weight: 300;
        line-height: 18px;
        letter-spacing: 0px;
        text-align: left;

      }
      .sub-label {
        margin-top: 6px;
        color: #C5BFE1;
        font-size: 12px;
        font-weight: 300;
        line-height: 18px;
        letter-spacing: 0px;
        text-align: left;
      }
      .wallet-id-info {
        color: #C5BFE1;
        margin-top: 10px;
        font-size: 12px;
        font-weight: 300;
        line-height: 18px;
        letter-spacing: 0px;
        text-align: left;
      }
      .text-area {
        padding: 14px;
        width: 100%;
        height: 183px;
        border-radius: 4px;
        resize: none;
        background-color: rgba(255, 255, 255, 0.1);
        border: 1px solid white;
        margin-top: 11px;
        caret-color: #FCBB11;
        font-size: 16px;
        line-height: 18px;
        padding-left: 13px;
        color: white;
        &:focus {
          outline: none;
        }
        &.error {
          border: 1px solid #FE6161;
          background: rgb(254, 97, 97, 0.1);
          color: #FE6161;
        }
      }
    }
    .check-section {
      margin-top: 24px;
      width: 100%;
      max-width: 350px;
      .subchecks {
        margin-left: 32px;
        :nth-child(1) {
          margin-top: 17px;
        }
        :nth-child(2) {
          margin-top: 17px;
        }
      }
    }
    .button {
      width: 350px;
      height: 58px;
      margin-top: 33px;
      @media screen and (max-width: 386px) {
        width: 100%;
      }
      &.disabled {
        background: #F6F4FF;
        color: #000000;
        cursor: default;
      }
    }
    .paragraphs {
      max-width: 540px;
      font-size: 16px;
      font-weight: 400;
      line-height: 25px;
      letter-spacing: 0em;
      text-align: center;
      .first {
        margin-top: 52px;
      }
      .second {
        margin-top: 25px;
      }
    }
    .stores {
      margin-top: 41px;
      display: flex;
      align-items: center;

      .store-button {
        &:first-child {
          margin-right: 20px;
        }
      }
    }
  }
}
</style>