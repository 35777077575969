<template>
  <div class="checkbox">
    <div
      class="img-section"
      :style="`width: ${size}px; height: ${size}px`"
      @click="inputValue = !inputValue"
      :class="inputValue ? 'check' : ''"
    >
      <img
        v-if="inputValue"
        :style="`width: ${size - 8}px; height: ${size - 10}px`"
        src="/images/screens/joinplatform/check.svg"
      >
    </div>
    <div class="label" :style="`padding-left: ${labelPadding}px; font-size: ${labelSize}px`">
      {{ label }}
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckboxField",
  props: {
    size: {
      type: Number,
      default: 24,
    },
    label: {
      type: String,
      required: true,
    },
    labelPadding: {
      type: Number,
      default: 14,
    },
    labelSize: {
      type: Number,
      default: 14,
    },
    newValue: {
      type: Boolean,
    }
  },
  data: () => ({
    inputValue: undefined,
    dontEmit: false,
  }),
  watch: {
    inputValue() {
      if (!this.dontEmit) {
        this.$emit('changed', this.inputValue);
      }
    },
    newValue() {
      this.dontEmit = true;
      this.inputValue = this.newValue;
      this.dontEmit = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.checkbox {
  width: max-content;
  height: max-content;
  display: flex;
  .img-section {
    border: 1px solid white;
    border-radius: 4px;
    &.check {
      background: #C5BFE1;
    }
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    align-items: center;
    user-select: none;
    img {
      margin-top: 0 !important;
    }
  }
  .label {
    max-width: 292px;
    text-align: left;
    @media screen and (max-width: 386px) {
      max-width: 200px;
    }
  }
}
</style>